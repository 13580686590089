<template>
  <v-card flat color="#f4f4f4">
    <v-toolbar class="v-nav-toolbar" color="#ffffff" flat>
      <div class="main-header-content fill">
        <div class="d-flex header-image-content">
          <router-link :to="'/'" class="no-decoration router-link-content">
            <img src="@/assets/logo-convites-abrapa.jpg" height="60" />
          </router-link>

          <template v-for="item in menuItems">
            <router-link
              :to="item.path"
              class="no-decoration mr-8 primary--text menu-item"
              :key="item.path"
              v-if="canSeeMenu(item.id)"
            >
              <span :class="{ 'menu-item-selected': isActive(item.path) }">
                {{ item.description }}
              </span>
            </router-link>
          </template>
        </div>

        <div class="d-flex user-info-content" v-if="user">
          <!-- Caso precise no span && canSeeUserInfo -->
          <span v-if="hasLoggedUser">
            Bem vindo <strong>{{ user.nome_completo | first_name }}</strong> -
            {{ cpf | clear_cpf_cnpj }}
          </span>
          <v-btn
            v-if="hasLoggedUser"
            class="d-flex align-center justify-center primary--text"
            text
            @click="doLogout"
          >
            <v-icon>mdi-logout</v-icon>
            Sair
          </v-btn>
        </div>
      </div>
    </v-toolbar>

    <slot />
  </v-card>
</template>

<script>
import { format } from "@fnando/cpf";
import { mapActions, mapGetters } from "vuex";
import { Profile } from "@/modules/inviteds/enums/profile.enum";
import { InvitedSystem } from "@/modules/core/enums/invited-system.enum";

const menuEnum = {
  ABR_SBRHVI: "abr_sbrhvi",
  EXCEPTION_SBRHVI: "exception_sbrhvi",
  UBA: "uba"
};

export default {
  name: "PageHeader",

  data: () => ({
    menuItems: [
      {
        id: menuEnum.ABR_SBRHVI,
        path: "/",
        description: "Convites ABR e SBRHVI"
      },
      {
        id: menuEnum.UBA,
        path: "/convites-uba",
        description: "Convites ABR-UBA"
      }
    ]
  }),

  computed: {
    ...mapGetters({
      user: "inviteds/getUser",
      hasLoggedUser: "getHasLoggedUser"
    }),

    cpf() {
      if (this.user) {
        return format(this.user.cpf);
      }
      return "";
    },

    canSeeUserInfo() {
      return (
        this.$vuetify.breakpoint?.name !== "xs" &&
        this.$vuetify.breakpoint?.name !== "sm"
      );
    }
  },

  methods: {
    ...mapActions("inviteds", ["logout"]),
    ...mapActions(["setNoHasLoggedUser"]),

    async doLogout() {
      this.setNoHasLoggedUser();
      this.logout();
      window.location.replace(process.env.VUE_APP_SSO_URL);
    },

    isActive(path) {
      if (
        this.$route.path.includes("/convites-uba") &&
        path === "/convites-uba"
      ) {
        return true;
      }

      if (this.$route.path.includes("/convite/") && path === "/") return true;

      return this.$route.path === path;
    },

    canSeeMenu(menuId) {
      if (!this.hasLoggedUser) {
        return false;
      }

      if (menuId === menuEnum.ABR_SBRHVI) {
        return this.canSeeInviteABRSBRHVI();
      }

      if (menuId === menuEnum.UBA) {
        return this.canSeeInviteABRUBA();
      }

      return [Profile.STATE.id, Profile.ABRAPA.id].includes(
        this.user.profile.id
      );
    },

    userIsAbrapa() {
      return Profile.ABRAPA.check(this.user?.profile?.id);
    },

    userIsState() {
      return Profile.STATE.check(this.user?.profile?.id);
    },

    canSeeInviteABRSBRHVI() {
      if (Profile.INVITED.check(this.user?.profile?.id)) {
        if (!this.user?.sistemas_convidados?.length) {
          return false;
        }
        return this.user.sistemas_convidados.some(invitedSystem =>
          InvitedSystem.ABR_SBRHVI.check(invitedSystem)
        );
      }
      return this.userIsAbrapa() || this.userIsState();
    },

    canSeeInviteABRUBA() {
      if (Profile.INVITED.check(this.user?.profile?.id)) {
        if (!this.user?.sistemas_convidados?.length) {
          return false;
        }
        return this.user.sistemas_convidados.some(invitedSystem =>
          InvitedSystem.ABR_UBA.check(invitedSystem)
        );
      }
      return this.userIsAbrapa() || this.userIsState();
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/stylesheets/styleguide";

.v-nav-toolbar {
  height: auto !important;
  .v-toolbar__content {
    height: 75px !important;
  }
}

.main-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header-image-content {
    align-items: center;
    gap: 12px;

    .router-link-content {
      margin-right: 60pxpx;
    }

    .menu-item {
      font-size: 18px;
      font-weight: 550;

      &:not(:last-child) {
        &:after {
          content: "";
          margin-left: 32px;
          border-right: 1px solid $secondary-color-1;
        }
      }

      .menu-item-selected {
        border-bottom: 1px solid $primary-color-1;
      }
    }

    .no-decoration {
      text-decoration: none;
    }
  }

  .user-info-content {
    align-items: center;
  }
}

@media screen and (max-width: 960px) {
  .v-nav-toolbar {
    .v-toolbar__content {
      height: fit-content !important;
    }
  }
  .main-header-content {
    flex-direction: column;
    justify-content: center;

    .header-image-content {
      flex-direction: column;
      justify-content: center;
      gap: 16px;

      .router-link-content {
        margin-right: 0;
        margin-bottom: 12px;
      }

      .menu-item {
        margin: 0 !important;
        &:not(:last-child) {
          &:after {
            content: "";
            margin-left: 0;
            margin-bottom: 32px;
            border-right: none;
            border-bottom: 1px solid $secondary-color-1;
          }
          &::backdrop {
            background-color: $primary-color-1;
          }
        }
      }
    }

    .user-info-content {
      justify-content: center;
      margin-top: 16px;
      gap: 8px;
    }
  }
}
</style>
