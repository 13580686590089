<template>
  <v-footer class="footer-abrapa" padless>
    <v-row justify="center" no-gutters class="row-content">
      <v-col class="logo-footer" cols="12" md="4">
        <img src="@/assets/abrapa.png" alt="" srcset="" />
      </v-col>
      <v-col class="text-footer" cols="9" md="6">
        <span>© {{ currentYear }} - ABRAPA - Todos os Direitos Reservados</span
        ><br />
        <span
          ><strong
            >Associação Brasileira dos Produtores de Algodão</strong
          ></span
        ><br />
        <span
          >Setor de Indústrias Bernardo Sayão (SIBS) Quadra 1, Conjunto B, Lote
          2, Edifício Abrapa, 1º andar CEP 71736-102 – Núcleo Bandeirante,
          Brasília-DF</span
        ><br />
        <strong>Site - </strong
        ><a href="https://suporte.abrapa.com.br" target="_blank"
          >suporte.abrapa.com.br
        </a>
        <strong>E-mail - </strong> atendimento.sistemas@abrapa.com.br
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
export default {
  data: () => ({
    links: ["Home", "About Us", "Team", "Services", "Blog", "Contact Us"]
  }),
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/styleguide";

.footer-abrapa {
  background-color: #fff !important;
  padding: 15px 50px;
  margin-top: -100px;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2) !important;

  .logo-footer {
    img {
      height: 90px;
    }
  }

  .text-footer {
    color: rgba(0, 79, 128, 0.698039215686274);
    font-size: 17px;
  }
}

@media screen and (max-width: 1150px) {
  .row-content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 12px;

    .logo-footer {
      display: flex;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 960px) {
  .footer-abrapa {
    margin-top: -200px;
    box-shadow: 0px 0px 0px 0px !important;
  }
}
</style>
