<template>
  <v-app>
    <v-main class="main-app">
      <router-view />
      <dialog-confirm-action ref="confirmDialog" />
      <Loading v-if="isLoading" />
    </v-main>
  </v-app>
</template>

<script>
import Loading from "./modules/core/views/components/Loading.vue";
import DialogConfirmAction from "@/modules/core/views/components/DialogConfirmAction";

import Vue from "vue";
import { mapGetters } from "vuex";

export default {
  name: "App",

  components: { Loading, DialogConfirmAction },

  computed: {
    ...mapGetters({
      isLoading: "isLoading"
    })
  },

  mounted() {
    // this is ok?
    Vue.prototype.$confirmDialog = this.$refs.confirmDialog;
  }
};
</script>
<style lang="scss">
@import "@/assets/stylesheets/styleguide";
@import "@/assets/stylesheets/forms";
@import "@/assets/stylesheets/styles";

@import "@/assets/stylesheets/ag-grid";

.main-app {
  background-color: #f4f4f4;
}

.v-sheet .theme--light .v-toolbar .v-toolbar--flat {
  height: fit-content;
}
</style>
